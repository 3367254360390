export enum PrismicDocumentTags {}

export enum PrismicTagPrefixes {
  AMBASSADOR = 'ambassador:',
  BRAND = 'brand:',
  CONTRIBUTOR = 'contributor:',
  LIST = 'list:',
  PRODUCT = 'product:',
  SERIES = 'series:',
  SPECIES = 'species:',
  STATE = 'state:',
  STYLE = 'style:',
  SUBCAT = 'subcat:',
  SUBCAT_TYPE = 'subcat-type:',
  WATERBODY = 'lake:',
}

export const filterTagsByPrefix = (tags: string[], prefix: PrismicTagPrefixes) => {
  return tags.filter((tag) => tag.includes(prefix)).map((tag) => tag.replace(prefix, ''));
};

export enum PrismicArticleTags {
  VIDEO = 'video',
}
