import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import VideoIcon from '../../images/icon-video.inline.svg';
import { articleCardDate } from '../../lib/dates';
import { getImgixPath } from '../../lib/imgix';
import { PrismicArticleTags } from '../../lib/prismic_tags';
import { RoutePaths } from '../../lib/routes';
import { PrismicArticle } from '../../models/prismic_article';
import * as styles from './article_card.module.less';

export const articleCardFragment = graphql`
  fragment ArticleCardFragment on PrismicArticle {
    uid
    tags
    type
    data {
      article_summary {
        raw
      }
      publication_date
      thumbnail {
        dimensions {
          width
          height
        }
        url
        alt
      }
      title {
        raw
      }
      partner {
        document {
          ... on PrismicPartner {
            id
            data {
              name
              logo {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface ArticleProps {
  article: PrismicArticle;
  partnerLink?: boolean;
}

export const ArticleCard: React.FC<ArticleProps> = (props) => {
  const { article, partnerLink } = props;
  const { thumbnail, article_summary, partner, publication_date, title } = article.data;
  return (
    <div>
      <Link to={`${RoutePaths.ARTICLES}${article.uid}/`} className={styles.link}>
        <div>
          <img
            src={getImgixPath(thumbnail.url, {
              w: 500,
            })}
            alt={thumbnail.alt}
            {...{ loading: 'lazy' }}
          />
        </div>
        <div className={styles.text}>
          <h3 className={styles.title}>{RichText.asText(title.raw)}</h3>
          <p className={styles.publicationDate}>{articleCardDate(publication_date)}</p>
          {article_summary && (
            <p className={styles.summary}>{RichText.asText(article_summary.raw)}</p>
          )}
        </div>
        {article.tags.includes(PrismicArticleTags.VIDEO) && (
          <div className={styles.badge}>
            <span className={styles.videoIcon}>
              <VideoIcon />
            </span>{' '}
            Video
          </div>
        )}
      </Link>
      {partnerLink && (
        <Link to={`${RoutePaths.PARTNERS}${partner.document.uid}`} className={styles.partnerLink}>
          {partner.document.data.logo && (
            <span className={styles.partnerImg}>
              <img src={partner.document.data.logo.url} />
            </span>
          )}
          <span>{partner.document.data.name}</span>
        </Link>
      )}
    </div>
  );
};
