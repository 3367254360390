import { graphql } from 'gatsby';
import React from 'react';
import { ArticleCard } from '../../components/article_card/article_card';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { SEO } from '../../components/seo';
import { GatsbyPageProps } from '../../gatsby-types';
import Layout from '../../layouts/layout';
import { PrismicArticle } from '../../models/prismic_article';
import { PrismicPartner } from '../../models/prismic_partner';
import * as styles from './partner.module.less';

// TODO: custom prismic html serializer for gatsby
// https://prismic.io/docs/reactjs/getting-started/prismic-gatsby

export const query = graphql`
  query($id: ID) {
    prismicPartner(prismicId: { eq: $id }) {
      data {
        name
        logo {
          alt
          url
          dimensions {
            height
            width
          }
        }
      }
      uid
    }
    allPrismicArticle(
      filter: { data: { partner: { id: { eq: $id } } } }
      sort: { fields: data___publication_date, order: DESC }
    ) {
      nodes {
        ...PrismicArticleFragment
      }
    }
  }
`;

const PartnerPage = (props: GatsbyPageProps) => {
  const partner = props.data.prismicPartner as PrismicPartner;
  if (!partner) {
    console.log('Partner not found');
    return null;
  }

  const { name } = partner.data;

  const articles = props.data.allPrismicArticle.nodes as PrismicArticle[];

  return (
    <Layout>
      <SEO title={name} />
      <ContentWrapper>
        <article>
          <h1>{name}</h1>

          <h2>Articles</h2>
          <ol className={styles.articles}>
            {articles.map((article) => (
              <li className={styles.article} key={article.uid}>
                <ArticleCard article={article} />
              </li>
            ))}
          </ol>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default PartnerPage;
